.btn {
	@include border-radius(0);
	@include transition();
	text-align: center;
	min-height: $btn-height;
	min-width: $btn-width;
	font-size: $base-font-size;
	font-weight: normal;
	display: inline-block;
	border: 1px solid;
    border-radius: 22px; 
	@include button-size();
	@include button-variant($white, $primary-color, $primary-color);
	&:hover, &:focus, &:active {
		@include button-variant($white, $primary-hover-color, $primary-hover-color);
		outline: none;
	}
}

.btn-lg {
	@extend .btn;
	width: 100%;
}

.btn-flat{
    border-radius: 0;
    min-width: 100%;
}
/*$btn-default-color, $btn-default-bg, $btn-default-border*/
.btn-1 {
	@include button-variant($white, $btn-1, $btn-1);
	&:hover, &:focus, &:active {
		@include button-variant($white, lighten($btn-1, 5%), lighten($btn-1, 5%));
	}
}

.btn-2 {
	@include button-variant($white, $btn-2, $btn-2);
	&:hover, &:focus, &:active {
		@include button-variant($white, lighten($btn-2, 5%), lighten($btn-2, 5%));
	}
}

.btn-3 {
	@include button-variant($white, $btn-3, $btn-3);
	&:hover, &:focus, &:active {
		@include button-variant($white, lighten($btn-3, 5%), lighten($btn-3, 5%));
	}
}

.btn-1-transparent {
	border: 2px solid;
	@include button-variant($white, transparent, $btn-1);
	&:hover, &:focus, &:active {
		@include button-variant($white, $btn-1, $btn-1);
	}
}
.btn-2-transparent {
	border: 2px solid;
	@include button-variant($white, transparent, $btn-2);
	&:hover, &:focus, &:active {
		@include button-variant($white, $btn-2, $btn-2);
	}
}
.btn-3-transparent {
	border: 2px solid;
	@include button-variant($white, transparent, $btn-3);
	&:hover, &:focus, &:active {
		@include button-variant($white, $btn-3, $btn-3);
	}
}
