/*Padding Margin marks*/

$i : 0;

@while $i <= 100{
	
	/*Mark #{$i}*/
	.p-#{$i}{
		padding: #{$i}px;
	}
	.p-t-#{$i}{
		padding-top: #{$i}px;
	}
	.p-b-#{$i}{
		padding-bottom: #{$i}px;
	}
	.p-l-#{$i}{
		padding-left: #{$i}px;
	}
	.p-r-#{$i}{
		padding-right: #{$i}px;
	}
	.p-tb-#{$i}{
		padding-top: #{$i}px;
		padding-bottom: #{$i}px;
	}
	.p-lr-#{$i}{
		padding-left: #{$i}px;
		padding-right: #{$i}px;
	}
	.m-#{$i}{
		margin: #{$i}px;
	}
	.m-t-#{$i}{
		margin-top: #{$i}px;
	}
	.m-b-#{$i}{
		margin-bottom: #{$i}px;
	}
	.m-l-#{$i}{
		margin-left: #{$i}px;
	}
	.m-r-#{$i}{
		margin-right: #{$i}px;
	}
	.m-tb-#{$i}{
		margin-top: #{$i}px;
		margin-bottom: #{$i}px;
	}
	.m-lr-#{$i}{
		margin-left: #{$i}px;
		margin-right: #{$i}px;
	}
	$i : $i + 10;
}