@font-face{
    font-family: modernist-regular;
    src: url("../../fonts/Sk-Modernist-Regular.otf");
}
@font-face{
    font-family: casper-regular;
    src: url("../../fonts/Casper.ttf");
}
@font-face{
    font-family: casper-bold;
    src: url("../../fonts/Casper Bold.ttf");
}