.background-image {
    min-height: 120px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &>img:first-child {
        display: none;
    }
}

.img-holder {
    position: relative;
}

.hover-overlay,
%full-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.section-head {
    h2 {
        margin-bottom: 20px;
    }
}

.light-bg{
    background-color: #f8f8f8;
}

.grad-1,
%grad-1 {
    color: #fff;
    background: rgba(74, 84, 140, 0.9);
    background: -moz-linear-gradient(top, rgba(74, 84, 140, 0.9) 0%, rgba(54, 162, 161, 0.9) 100%);
    background: -webkit-linear-gradient(top, rgba(74, 84, 140, 0.9) 0%, rgba(54, 162, 161, 0.9) 100%);
    background: linear-gradient(to bottom, rgba(74, 84, 140, 0.9) 0%, rgba(54, 162, 161, 0.9) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(74, 84, 140, 0.9)', endColorstr='rgba(54, 162, 161, 0.9)', GradientType=0);
}

.grad-2,
%grad-2 {
    color: #fff;
    background: rgba(97, 67, 133, 0.9);
    background: -moz-linear-gradient(top, rgba(97, 67, 133, 0.9) 0%, rgba(81, 99, 149, 0.9) 100%);
    background: -webkit-linear-gradient(top, rgba(97, 67, 133, 0.9) 0%, rgba(81, 99, 149, 0.9) 100%);
    background: linear-gradient(to bottom, rgba(97, 67, 133, 0.9) 0%, rgba(81, 99, 149, 0.9) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(97, 67, 133, 0.9)', endColorstr='rgba(81, 99, 149, 0.9)', GradientType=0);
}

.grad-3,
%grad-3 {
    color: #fff;
    background: rgba(22, 160, 133, 0.9);
    background: -moz-linear-gradient(top, rgba(22, 160, 133, 0.9) 0%, rgba(244, 208, 63, 0.9) 100%);
    background: -webkit-linear-gradient(top, rgba(22, 160, 133, 0.9) 0%, rgba(244, 208, 63, 0.9) 100%);
    background: linear-gradient(to bottom, rgba(22, 160, 133, 0.9) 0%, rgba(244, 208, 63, 0.9) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(22, 160, 133, 0.9)', endColorstr='rgba(244, 208, 63, 0.9)', GradientType=0);
}

.grad-4,
%grad-4 {
    color: #fff;
    background: #c04848;
    background: -moz-linear-gradient(top, rgba(192, 72, 72, 0.9)0%, rgba(72, 0, 72, 0.9) 100%);
    background: -webkit-linear-gradient(top, rgba(192, 72, 72, 0.9) 0%, rgba(72, 0, 72, 0.9) 100%);
    background: linear-gradient(to bottom, rgba(192, 72, 72, 0.9) 0%, rgba(72, 0, 72, 0.9) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(192, 72, 72, 0.9)', endColorstr='rgba(72, 0, 72, 0.9)', GradientType=0);
}

%display-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.contentWSidebar-container {
    float: right;
    @include bp(M) {
        float: none;
    }
}

.sidebar-container {
    .side-widget {
        @include add-border(1px, #dcdcdc, all);
        @include bp(M, S) {
            min-height: 220px;
        }
        ul>li {
            &:last-child {
                margin-bottom: 0;
            }
        }
        .side-widget-credit {
            display: block;
            &:before {
                content: '';
                border: 1px solid #f67b18;
                padding: 0 8px;
                border-radius: 50%;
                margin-right: 10px;
            }
        }
    }
    .enquiryStatus {
        position: relative;
        padding-left: 20px;
        &:before {
            content: '';
            padding: 5px;
            background-color: #b9b9b9;
            position: absolute;
            border-radius: 50%;
            top: 3px;
            left: 0px;
        }
        &.new:before {
            background-color: #36a2a1;
        }
        &.shortlist:before {
            background-color: #ffabb7;
        }
        &.viewed:before {
            background-color: #fdedc3;
        }
    }
    .side-widget,
    .side-notification {
        padding: 20px;
        margin-bottom: 20px;
    }
    .side-widget-title,
    .side-notification-title {
        color: $primary-color;
        padding-bottom: 15px;
        margin-bottom: 15px;
        @include add-border(1px, #f1f1f1, bottom);
    }
    .side-notification {
        .side-notification-desc {
            color: #e2e2e2;
        }
        a {
            display: block;
            margin-top: 15px;
            font-family: $base-font-bold-family;
        }
    }
}

.tab-container {
    .nav-tabs {
        &>li {
            position: relative;
            &>a {
                border: 1px solid #ddd;
                border-radius: 0;
                margin: 0;
                border-bottom: none;
                &:hover {
                    background-color: transparent;
                }
            }
            &:not(:last-child) {
                &>a {
                    border-right-color: transparent;
                }
            }
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                border-bottom: 3px solid transparent;
            }
            &.active,
            &.active:hover,
            &.active:focus {
                &>a {
                    color: $primary-color;
                    font-family: casper-bold;
                    border-bottom: none;
                    background-color: transparent;
                }
            }
            &.active:before,
            &:hover:before,
            &:focus:before {
                border-bottom: 3px solid #4A548E;
            }
        }
        @include bp(S) {
            &>li {
                //width: auto;
                //margin-left: auto;
                &>a {
                    //padding: 10px 5px;
                }
                &:not(:last-child) {
                    &>a {
                        border-right: 1px solid #ddd;
                    }
                }
                &.active+li>a,
                &:hover+li>a {
                    border-top-color: transparent;
                }
            }
        }
    }
    margin-bottom: 20px;
}

.table-default {
    thead {
        color: $primary-color;
    }
    td {
        border-bottom: 1px solid #ddd;
    }
    .tr-disable {
        color: #a2a2a2;
    }
}

.paging {
    margin-top: 20px;
    text-align: center;
    &>li {
        display: inline-block;
        padding: 2px;
        a {
            color: #a2a2a2;
        }
        &.active {
            a {
                color: $primary-color;
            }
        }
        &.prev,
        &.next {
            border: 1px solid #ddd;
            margin: 0 10px;
            &:hover {
                background-color: #ddd;
                color: $primary-color;
            }
            a {
                padding: 2px 8px;
            }
        }
    }
}

.default-accordian {
    border-radius: 0;
    margin-bottom: 30px;
    &>.panel-heading {
        padding: 0;
        border-radius: 0;
        border: 1px solid $primary-dark-color;
    }
    h4.panel-title a {
        background: $primary-dark-color;
        color: $white;
        font-size: 1.25em; //20px;
        position: relative;
        &:before,
        &:after {
            font-family: 'FontAwesome';
            color: $white;
        }
        &:after {
            content: "\f106";
            font-size: 26px;
            line-height: 22px;
            position: absolute;
            right: 10px;
            -webkit-transition: transform 0.3s ease;
            transition: transform 0.3s ease;
        }
        &.collapsed:after {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }
        &.no-arrow:after{
            content: '';
        }
    }
}

.default-form {
    label {
        font-weight: normal;
    }
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    textarea {
        padding: 10px 15px;
        border: 1px solid #ddd;
        border-radius: 0;
        text-align: left;
        background-color: $white;
        margin-bottom: 20px;
        box-shadow: none;
        min-width: 100%;
        min-height: 40px;
    }
}
.full-input{
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="search"]{
        width: 100%;
    }
}
.profile-img-container {
    position: relative;
    width: 100%;
    @include bp(M) {
        margin-bottom: 30px;
    }
    img {
        width: 100%;
    }
    .profile-img-edit {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.25);
        transition: opacity 0.3s ease;
    }
    .profile-img-edit-container {
        &>a {
            border: 1px solid $white;
            color: $white;
            padding: 2px 10px;
            position: absolute;
            top: 5px;
            right: 5px;
            &:hover {
                background-color: $white;
                color: $default-color;
            }
        }
    }
    &:hover {
        .profile-img-edit {
            visibility: visible;
            opacity: 1;
        }
    }
}

.inner-content {
    padding-bottom: 30px;
    .inner-title-container {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ddd;
        h5 {
            color: $primary-color;
            float: left;
        }
        .edit {
            float: right;
            color: $primary-color;
            border: 1px solid $primary-color;
            padding: 2px 10px;
            &:hover {
                background-color: $primary-color;
                color: $white;
            }
        }
    }
    &:after, .inner-title-container:after {
        content: '';
        display: table;
        clear: both;
    }
}

.circular-progress-bar {
    position: relative;
    div {
        position: absolute;
        height: 130px;
        width: 130px;
        border-radius: 50%;
    }

    div span {
        position: absolute;
        font-size: 40px;
        font-family: $base-font-bold-family;
        line-height: 115px;
        height: 120px;
        width: 120px;
        left: 5px;
        top: 5px;
        text-align: center;
        border-radius: 50%;
        color: $primary-color;
        background-color: $white;
    }

    .background {
        background-color: #ddd;
    }

    .rotate {
        clip: rect(0 60px 200px 0);
        background-color: $primary-color;
    }

    .left {
        clip: rect(0 60px 200px 0);
        opacity: 1;
        background-color: #ddd;
    }

    .right {
        clip: rect(0 60px 200px 0);
        transform: rotate(180deg);
        opacity: 0;
        background-color: $primary-color;
    }

}

.photo-gallery-container{
    .photo-gallery-img{
        min-height: 200px;
        margin-bottom: 20px;
    }
}

.div-remove {
    padding: 15px 0;
    a{
        color: #f25f85;
        &:before{
            content: 'X';
            padding: 1px 3px;
            border: 1px solid #f25f85;
            border-radius: 0;
            margin-right: 5px;
            font-size: 12px;
        }
    }
}

.separator {
    border-bottom: 1px solid #ddd;
}

body{
    padding: 0 !important;
}

.modal.in{
    padding-right: 0 !important;
}

.modal-content{
    padding: 15px;
    border-radius: 0;
}

.modal-backdrop.in {
    filter: alpha(opacity=20);
    opacity: .2;
}

.modalVerticalAlignHelper {
    display:table;
    height: 100%;
    width: 100%;
    pointer-events:none;
    .modalVerticalAlignCenter {
        /* To center vertically */
        display: table-cell;
        vertical-align: middle;
        pointer-events:none;
    }
    .modal-content {
        /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
        width:inherit;
        height:inherit;
        /* To center horizontally */
        margin: 0 auto;
        pointer-events:all;
    }
}

/*For rating stars Start

.fa-star, label.star {
	font-size: 20px;
	color: $black;
}

.five-star i,
.four-star i:nth-child(-n+4),
.three-star i:nth-child(-n+3),
.two-star i:nth-child(-n+2),
.one-star i:nth-child(-n+1) {
	color: $primary-color;
}*/


/*Form Rating Start

div.stars {
	display: inline-block;
}

input.star {
	float: right;
	width: 1px;
	visibility: hidden;
	height: auto;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

label.star {
	float: right;
	padding: 3px;
    &:before {
        content: '\f005';
        font-family: FontAwesome;
    }
}

input.star:checked ~ label.star:before,
input.star:hover ~ label.star {
	color: $primary-color;
	cursor: pointer;
}*/
