@-webkit-keyframes slide-down {
  from   { top: -50px; }
  to { top: 0; }
}
@-moz-keyframes slide-down {
  from   { top: -50px; }
  to { top: 0; }
}
@-o-keyframes slide-down {
  from   { top: -50px; }
  to { top: 0; }
}
@keyframes slide-down {
  from   { top: -50px; }
  to { top: 0; }
}
.navbar {
	//background: rgba(#ffffff, 0.2);
    min-height: 110px;
	@include border-radius(0);
    @include bp(S){
        min-height: auto;
    }
    &>.container{
        @include add-border(1px, rgba($white, 0.3), bottom);
    }
}

.navbar,
.navbar ul li {
	margin-bottom: 0;
}
.nav_with_header{
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
}
@media screen and (min-width: 767px){
    .navbar-nav{
        float: none;
        display: -webkit-box;   // OLD - iOS 6-, Safari 3.1-6, BB7 
        display: -ms-flexbox;  // TWEENER - IE 10 
        display: -webkit-flex; // NEW - Safari 6.1+. iOS 7.1+, BB10 
        display: flex;         // NEW, Spec - Firefox, Chrome, Opera 
        //justify-content: center;
        //-webkit-box-pack: center; // justify-content 
    }
}

.navbar-ingits .navbar-nav{
    @include bp(S){margin: 0 -15px;};
    &>li{
        @include bp(S){border-bottom: 1px solid rgba($primary-dark-color, 0.5);};
        &>a{
            font-size: 18px;
            padding-top: 10px;
            padding-bottom: 10px;
            color: $white;
        }
    }
    &>li>a:hover, &>li>a:focus, &>li>a:active, 
    &>.active a, &>.open a,
    &>.active a:hover, &>.active a:focus, &>.active a:active, 
    &>.open a:hover, &>.open a:focus, &>.open a:active{
        border-bottom: 1px solid rgba($white, 0.5);
        background-color: transparent;
    }
}

.navbar.nav-sticky{
	position: fixed;
	top: 0;
	animation: slide-down 300ms;
}

.navbar-brand{
    font-family: $base-font-bold-family;
    font-size: 4.688em;//75px
    line-height: 110px;
    float: none;
    @extend %text-white-a;
    @include bp(S){
        font-size: 36px;
        line-height: 45px;
        padding: 0 15px;
    }
}

/*Hamburger Menu Style*/
.navbar-ingits .navbar-toggle{
    border: none;
    .icon-bar{
        background-color: rgba($white, .9);
    }
    &:focus, &:hover{
        background-color: $primary-dark-color;
        .icon-bar{
            background-color: $white;
        }   
    }
}
@include bp(S){
    .navbar-ingits .navbar-nav{
        padding-top: 0 !important;
    }
}