body{
	font:{
		family: $base-font-family;
		size: $base-font-size;
	}
	letter-spacing: $base-font-spacing;
	color: $default-color;
	transition: .3s all ease;
	@include bp(S, XXS) { font-size: $base-mobileFont-size; }
}

p{
    font-family: $base-font-family;
    font-size: 1em;//18px
	color: $default-color;
    margin: 0;
}

h1 {
    font-size: 2.5em//40px
}

h2 {
    font-size: 2.25em//36px
}

h3 {
    font-size: 1.875em//30px
}

h4 {
    font-size: 1.5em//24px
}

h5 {
    font-size: 1.25em//20px
}

h6 {
    font-size: 1em//16px
}

small{
    font: {
        size: 0.875em;//14px
    }
}

h1,h2,h3,h4,h5,h6{
    font-family: $base-font-bold-family;
	margin: 0;
}

.text-strong, .text-bold, strong{
	font-family: $base-font-bold-family;
}

.text-white, %text-white{
    color: $white;
}
%text-white-a{
    color: $white;
    &:hover, &:focus{
        color: #e5e5e5;
    }
}
.text-primary, .text-primary *{
    color: $primary-color;
}

.text-default, .text-default *{
    color: $default-color;
}

.text-dark{
    color: $primary-dark-color;
}

a{
	color: $default-color;
    @include transition();
	&:hover, &:focus{
		text-decoration: none;
		color: $primary-dark-color;
	}
}

ul{
	color: $primary-color;
	li{
		margin-bottom: 10px;
	}
	&.list-none{
		list-style: none;
	}
}

input, textarea, button{
	border-radius: 0;
}

.casper-regular{
    font-family: $base-font-family;
}
.casper-bold{
    font-family: $base-font-bold-family;
}
.modernist-regular{
    font-family: $base-font-header-family
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="search"],
textarea{
    padding: 10px;
    border: none;
    border-radius: 22px;
    min-width: 250px;
    text-align: center;
    background-color: #f2f2f2;
    color: $default-color;
    &:focus{
        outline: none;
    }
}
textarea{
    @include bp(XS){
        width: 100%;
    }
}
.search{
    position: relative;
    width: 250px;
    margin: 0 auto;
    input[type="search"]{
        padding-left: 35px;
    }
}
.search:before{
    content: "\f002";
    color: #cdcdcd;
    font-size: 20px;
    font-family: "FontAwesome";
    position: absolute;
    left: 0;
    padding: 6px 10px;
}