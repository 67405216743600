/*Home Slider*/
.home-slider{
    .img-holder{
        min-height: 70vh;
        @include bp(XS){
            min-height: 100vh;
        }
    }
    .hover-overlay{
        @extend %grad-1;
    }
    .carousel-indicators li{
        background-color: rgba($white, 0.5);
        border-color: rgba($white, 0.5);
        &.active{
            background-color: rgba($white, 0.8);
            border-color: rgba($white, 0.8);
        }
    }
    .slider-content{
        position: relative;
        text-align: center;
        color: $white;
        h4{
            padding: 30px 0 50px;
        }
    }
}
/*Home Service*/
.service-image.background-image{
    min-height: 190px;
    max-height: 190px;
    margin-bottom: 20px;
    background-size: 120%;
    &.service-highlight{
        min-height: 270px;
        max-height: 270px;
    }
    @include transition(all, 0.5s, ease);
    .hover-overlay{
        background: $black;
        opacity: 0.3;
        @include transition(opacity, 0.5s, ease);
    }
    &:hover{
        cursor: pointer;
        background-size: 100%;
        //min-height: 300px;
        .hover-overlay{
            @extend %grad-1;
            opacity: 1;
        }
    }
}
.service-detail{
    position: relative;
    padding: 30px;
    color: $white;
    h2{
        margin-bottom: 20px;
    }
}
.other-services .search>input[type="search"]{
    text-align: left;
}
/*Home Why Choose Us*/
.chooseUs-bg{
    .hover-overlay{
        @extend %grad-1;
    }
    .chooseUs-section{
        position: relative;
    }
}
.chooseUs-content{
    text-align: center;
    margin-bottom: 20px;
    .chooseUs-contentImage{
        min-height: 150px;
    }
    h4{
        margin-bottom: 20px;
    }
    @include bp(S){
        border: 1px solid rgba($white, 0.5);
        padding: 20px;
    }
}
/*Home Latest News*/
.latestNews-content{
    margin-bottom: 20px;
    .latestNews-contentImage, h4, small{
        margin-bottom: 20px;
    }
    small{
        color: rgba($default-color, .5);
        display: block;
    }
    @include bp(S){
        border: 1px solid rgba($default-color, 0.2);
        .latestNews-detail{
            padding: 0 15px 15px 15px;
        }
    }
}
.latestNews-contentImage.background-image{
    min-height: 190px;
    .hover-overlay{
        @extend %grad-1;
        opacity: 0.3;
    }
}



/*Agency Register*/
.inst_reg_header{
    text-align: center;
    padding-top: 220px;
    &.background-image{
        min-height: 450px;
        background-position: top center;
    }
    @include bp(S){
        padding-top: 110px;
        &.background-image{
            min-height: 380px;
        }
    }
}
.section-title{
    text-align: center;
    padding: 0 15px 30px;
    h4, h2{
        color: $primary-color;
    }
}

.agencyRegisterForm{
    &>.col-md-6{
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 20px;
        &.first{
            padding-left: 0;
            border-right: 1px solid #ddd;
        }
        &.second{
            padding-right: 0;
        }
        @include bp(S){
            padding: 20px 0;
            &:first-child{
                border-right: none;
                border-bottom: none;
            }
        }
    }
}

.chooseUs-image.background-image{
    min-height: 230px;
    max-height: 230px;
    margin-bottom: 20px;
    background-size: 110%;
    @include transition(all, 0.3s, ease);
    &:hover{
        cursor: pointer;
        background-size: 100%;
    }
}

.faq-title{
    background-color: #505678;
    padding: 20px;
    margin-bottom: 30px;
    h5{
        position: relative;
        color: $white;
        padding-left: 30px;
        &:before{
            content: '\f059';
            font-size: 26px;
            line-height: 20px;
            font-family: 'FontAwesome';
            position: absolute;
            left: 0;
        }
    }
}

.faq-accordians{
    .default-accordian {
        border: none;
        box-shadow: none;
        margin-bottom: 0;
        &> .panel-heading{
            border: 1px solid #ddd;
            margin-bottom: 20px;
        }
        &> .panel-heading+ .collapse.in{
            margin-top: -20px;
        }
        h4.panel-title a{
            padding: 12px 30px 12px 12px;
            background-color: $white;
            color: #505678;
            &:after{
                color: #505678;
            }
        }
    }
}

.getHired{
    &.background-image{
        position: relative;
        background-position: left center;
    }
    h2{
        color: $primary-color;
        margin-bottom: 20px;
    }
    p{
        margin-bottom: 20px;
    }
    .author{
        float: left;
        border-top: 1px solid #ddd;
        span{
            color: $primary-color;
        }
    }
    .getHired-overlay{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.5);
        @include bp(S){
            background-color: rgba(255, 255, 255, 0.8);
        }
    }
}

.transactionContact{
    color: $primary-color;
    font-family: $base-font-bold-family;
}

.forgotPW{
    float: left;
}
.signup, .forgotPW a, .terms-cond a, .already-member, .companyRegisterLink a{
    font-family: $base-font-bold-family;
    color: $primary-color;
}
.terms-cond{
    padding: 15px 0;
    font-weight: 300;
    font-size: 14px;
    text-align: center;
}
/*Agency RegisterEnd */
.modal.login,
.modal.login h1{
    color: $default-color;
}

/*Magnific POPUP*/
.mfp-bg.mfp-sign{
    opacity: 0.2;
}
.panel{
    border-radius: 0;
}
.mfp-close{
    margin-right: 15px;
}

.mfp-wrap .mfp-ajax-holder .mfp-content{
    width: 600px;
    @include bp(S){
        width: 90%;
    }
}
/*Magnific POPUP End*/