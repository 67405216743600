header{
    padding-top: 60px;
    padding-left: 0;
    background-color: transparent;
    min-height: 110px;
    @extend %text-white;
    &.home{
        padding-top: 20px;
        position: absolute;
        right: 0;
        left: 0;
        z-index: 1;
        .header-logo{
            padding-top: 10px;
            padding-bottom: 22px;
        }
        &>.container{
            padding-bottom: 0;
            @include add-border(1px, rgba($white, 0.3), bottom);
        }
    }
    &.inner-header{
        background-color: #505678;
        padding-top: 10px;
        max-height: 130px;
        &>.container{
            border: none;
        }
    }
    &>.container{
        padding-bottom: 60px;
        @include add-border(1px, rgba($white, 0.3), bottom);
    }
    h1{
        font: {
            size: 3.125em;//50px;
            family: $base-font-header-family;
        }
        @include bp(S){
            font-size: 2.5em;
        }
    }
    .question-close {
        padding-top: 16px;
        cursor: pointer;
        a{
            @extend %text-white-a;
            padding: 12px 15px;
            background-color: rgba($white,.5);
            border-radius: 50%;
            @include transition();
        }
        &:hover a{
            background-color: rgba($white,.2);
        }
        @include bp(S){
            padding-top: 10px;
            position: absolute;
            right: 15px;
            a{
                padding: 12px 14px;
            }
        }
    }
    &.bg{
        background-color: gray;
    }
    .item.message{
        display: inline-block;
        position: relative;
        width: 36px;
        height: 15px;
        top: -12px;
        margin-right: 15px;
        .fa{
            font-size: 26px;
            line-height: 26px;
            position: absolute;
            top: 5px;
        }
        .msgCount{
            position: absolute;
            color: #505678;
            right: 0;
            top: 5px;
            background-color: #fff;
            border-radius: 50%;
            padding: 0 5px;
            font-size: 12px;
        }
    }
    @include bp(S){
        padding-top: 10px;
        &.inner-header{
            padding-top: 0;
            min-height: 60px;
            &>.container{
                padding-bottom: 0;
            }
            .header-button{
                padding-top: 8px !important;
            }
        }
        &.home{
            padding-top: 10px;
        }
        &>.container{
            padding-bottom: 10px;
        }
    }
}

.header-logo{
    float: left;
    max-width: 200px;
    padding-top: 22px;
    @include bp(S){
        max-width: 100px;
        padding-top: 15px;
    }
    a {
        font: {
            family: $base-font-bold-family;
            size: 4.688em; //75px
        }
        @include bp(S){
            font-size: 3em;
        }
        span {
            font: {
                family: $base-font-family;
                size: 12px;
            }
        }
        @extend %text-white-a;
    }
}
.header-button{
    float: right;
    .fa-user-o{
        padding-right: 10px;
    }
    @include bp(S){
        .login{
            min-width: 43px;
            padding: 8px 12px;
        }
        .fa-user-o{
            padding-right: 0;
        }
    }
}
/*.header.background-image{
    min-height: 300px;
    @include bp(XS){
        min-height: 300px;
    }
}

.header-breadcrumb{
    .breadcrumb{
        display: inline-block;
        padding: 0;
        margin: 0;
        list-style: none;
        border-radius: 0;
        background-color: transparent;
        &>li+li:before{
            color: $secondary-color;
        }
    }
    a{
        color: $secondary-color;
        &:hover, &:focus{
            color: $primary-dark-color;
        }
    }
    .active{
        color: $primary-dark-color;
    }
}*/
