$default-color: #484848;
$primary-color : #4a548e;
$primary-hover-color : #5a66ad;
$primary-dark-color: #505678;
$secondary-color: #d48e1f;
$black: #000;
$white: #fff;

/*Font Properties*/
$base-font-size : 16px;
$base-mobileFont-size : 14px;
$base-font-spacing: 0.5px;
$base-font-family : casper-regular;
$base-font-bold-family: casper-bold;
$base-font-header-family: modernist-regular;

/*Button Properties*/
$btn-height: 40px;
$btn-width: 200px;
$btn-1: #36a2a1;
$btn-2: #4a548e;
$btn-3: #d48e1f;
