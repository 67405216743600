.no-pad {
    padding: 0;
}

.no-margin {
    margin: 0;
}


/*Old Style Normalize*/

.main-container {
    margin-top: 0;
}

.compare-the-course-inner-header {
    display: none;
}

.inner-header .align-vertical i.fa {
    font-size: 20px;
    line-height: 16px;
    margin-top: 0;
   // padding-right: 0;
}

.btn-group.open .dropdown-toggle {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.inner-header .header-button .login{
    padding: 7px;
}
.courses-menu-new {
    background: none;
    .navbar {
        border: none;
        min-height: auto;
        .navbar-nav {
            border-bottom: 1px solid #ddd;
            &>li {
                &>a {
                    padding: 15px 0;
                    margin-right: 30px;
                }
                &>a:focus, &>a:hover, &.open>a, &.open>a:focus, &.open>a:hover, &.active>a{
                    background: $white;
                    color: #4a548e;
                    position: relative;
                    &:before{
                        content: '';
                        position: absolute;
                        bottom: -1px;
                        width: 100%;
                        border-bottom: 3px solid #4A548E;
                    }
                }
            }
        }
        a {
            color: $default-color;
        }
        .dropdown-menu a{
            &:hover, &:focus{
                background-color: #4A548E;
                color: $white;
            }
        }
        &>.container {
            border-bottom: none;
        }
        .navbar-collapse {
            padding: 0;
        }
    }
    .navbar-toggle{
        background-color: #505678;
        border-color: #505678;
        border-radius: 0;
    }
    .navbar-nav{
        margin: 0;
    }
    .container>.navbar-collapse{
        margin: 0;
    }
    @include bp(S){
        .dropdown-menu li a{
            color: $default-color;
        }
        .navbar{
            border-bottom: 1px solid #ebebeb;
            .navbar-nav {
                &>li {
                    &>a{
                        padding: 10px 0 10px 15px;
                    }
                    &>a:focus, &>a:hover, &.open>a, &.open>a:focus, &.open>a:hover, &.active>a{
                        &:before{
                            bottom: 0;
                            border-bottom: none;
                            border-left: 3px solid #4a548e;
                            height: 100%;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}

footer{
    margin: 0;
}
