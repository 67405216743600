footer{
    background-color: transparent;
    padding-bottom: 40px;
    /*position: absolute;
    right: 0;
    left: 0;
    bottom: 0;*/
    &>.container{
        padding-top: 40px;
        //@include add-border(1px, rgba($white, 0.3), top);
    }
    @extend %text-white;
    @include bp(S){
        padding-bottom: 10px;
        &>.container{
            padding-top: 0;
        }
    }
    &.bg{
        background-color: #505678;
    }
    a{
        @extend %text-white-a;
    }
}

.footer-logo{
    max-width: 200px;
    padding-bottom: 15px;
    @include bp(S){
        max-width: 100px;
        padding-top: 15px;
    }
    @include bp(XS){
        margin: auto;
    }
    a {
        font: {
            family: $base-font-bold-family;
            size: 3.375em; //55px
        }
        span {
            font: {
                family: $base-font-family;
                size: 12px;
            }
        }
        &:hover{
            @extend %text-white;
        }
    }
}
.footer-socialIcon{
    font-size: 2.25em; //36px
    a{
        padding: 10px;
        &:last-child{
            padding-right: 0;
        }
        &:hover i{
            text-shadow: 0px 1px 1px $white;
        }
        &.twitter:hover{
            color: #55acee;
        }
        &.linkedIn:hover{
            color: #0077B5;
        }
    }
}
.footer-copyright, .footer-terms{
    opacity: 0.6;
    p{
        margin: 0;
    }
}
@media screen and (min-width: 480px){
    .footer-logo, .footer-copyright{
        float: left;
    }
    .footer-socialIcon, .footer-terms{
        float: right;
    }
    .footer-socialIcon{
        padding-top: 15px;
    }
}
@media screen and (max-width: 480px){
    .footer-logo, .footer-socialIcon, .footer-copyright, .footer-terms{
        text-align: center;
    }
    .footer-logo a{
        @include add-border(1px, rgba($white, 0.5), bottom);
        padding-bottom: 10px;
    }
    .footer-copyright{
        @include add-border(1px, rgba($white, 0.5), top-bottom);
        padding: 10px 0;
        margin-bottom: 10px;
    }
}