body{
    min-height: 100vh;
    //@extend %grad-4;
}
/*Error Page*/
.error .error-content{
    padding: 200px 0;
    h2{
        color: $primary-color;
    }
    &>p{
        margin: 30px;
    }
}
/*Error Page End*/

/*Question Section Start*/
.question-container{
    position: relative;
    &>form{
        height: 100%;
    }
    .nav-tabs {
        border-bottom: none;
        border-radius: 10px;
        overflow: hidden;
        @extend %display-flex;
        flex-wrap: nowrap;
        @include bp(XS){
            display: block;
        }
        &>li{
            width: 100%;
            height: 20px;
            text-align: center;
            background-color: rgba($white, 0.3);
            &>a{
                background-color: transparent;
                border: none;
                border-radius: 0;
                padding: 6px;
                margin: 0;
                pointer-events: none;
                transition: none;
                @include transition(width, 1s, ease);
                width: 1px;
            }
            &.answered {
                background-color: #604586;
                a{
                    width: 100%;
                    border-color: #604586;
                    background-color: #604586;
                    color: $white;
                }
            }
            &.active a, &:not(.disabled) a:focus, &:not(.disabled) a:hover{
                width: 100%;
                border: none;
                background: #4b2f72; /* For browsers that do not support gradients */
                background: -webkit-linear-gradient(-90deg, #4b2f72, #604586); /* For Safari 5.1 to 6.0 */
                background: -o-linear-gradient(-90deg, #4b2f72, #604586); /* For Opera 11.1 to 12.0 */
                background: -moz-linear-gradient(-90deg, #4b2f72, #604586); /* For Firefox 3.6 to 15 */
                background: linear-gradient(-90deg, #4b2f72, #604586); /* Standard syntax */
                //background-color: #553a7b;
                color: $white;
            }
            &:first-child{
                background-color: #604586;
            } 
            &:last-child{
                margin-right: 0;
            }
            &.disabled{
                pointer-events: none;
            }
        }
    }
    .tab-content{
        padding: 60px 0;
        overflow: hidden;
        @include bp(S){
            padding: 30px 0 175px;
        }
        &>.tab-pane{
            transform: translateX(100%);
            @include transition(all, .3s, ease);
            &>div{
                @include bp(S){
                    padding: 0;
                }
            }
            &.next-clicked, &.active{
                transform: translateX(0);
            }
            &.next-clicked:not(.active){
                transform: translateX(-100%);
            }
            &.prev-clicked:not(.active){
                transform: translateX(100%);
            }
        }
    }
}
.question p{
    font-size: 1.25em;//20px
    color: $white;
}
.question .question-title{
    font-size: 2.5em;//40px
    color: $white;
}

.question-nav-button{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-family: $base-font-bold-family;
    font-size: 1.125em;//18px
    .prev{ 
        float: left;
        padding-top: 10px;
        a{
            position: relative;
            padding-left: 20px;
            @extend %text-white-a;
            &:before{
                content: "\f104";
                font-size: 1.875em;//30px
                line-height: 0.7em;//20px
                font-family: "FontAwesome";
                position: absolute;
                left: 0;
            }
            &.disabled{
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
    .next{
        float: right;
        a{
            font-size: 1.125em;//18px
            &.question-submit{
                display: none;
            }
        }
    }
}

.question-error-container{
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10%;
    padding: 10px 15px;
    text-align: center;
    border-radius: 28px;
    p{
        &:before{
            content: "\f00d";
            font-family: 'FontAwesome';
            padding-right: 5px;
        }
    }
}
%radio-hover{
    background-color: rgba($white, 0.2);
    border-color: rgba($white, 0.1);
    box-shadow: 0px 0px 6px 0px rgba($white, 0.3);
}

.question-radio, input[type="radio"]{
    opacity: 0;
    width: 0;
    &:focus + .question-radio-label, &:checked + .question-radio-label{
        @extend %radio-hover;
    }
}
.question-radio-label {
    margin: 0 0 30px 0;
    padding: 40px 0;
    border: 1px solid rgba($white, 0.5);
    border-radius: 50%;
    height: 100px;
    width: 100px;
    font-weight: normal;
    @include transition;
    &:hover{
        cursor: pointer;
        @extend %radio-hover;
    }
}

.question-checkbox{
    visibility: hidden;
    &:first-child{
        margin-left: 60px;
    }
    &:checked + .question-checkbox-label:after{
        opacity: 1;
    }
    @include bp(S){
        float: left;
        &:first-child{
            margin-left: 0;
        }
    }
}
.question-checkbox-label{
    position: relative;
    margin-right: 35px;
    font-size: 1.5em;//24px
    font-weight: normal;
    margin-bottom: 15px;
    &:before{
        content: '';
        width: 30px;
        height: 30px;
        background-color: $white;
        position: absolute;
        left: -38px;
        top: -2px;
        border-radius: 50%;
    }
    &:after{
        content: '';
        width: 16px;
        height: 16px;
        background-color: $primary-color;
        position: absolute;
        left: -31px;
        top: 5px;
        border-radius: 50%;
        opacity: 0;
    }
    &:hover:after{
        opacity: 0.3;
    }
    @include bp(S){
        display: block;
        text-align: left;
        padding-left: 40px;
        &:before{
            left: 0px;
        }
        &:after{
            left: 7px;
        }
    }
    
}

/*Question Section End*/



/*Dashboard Start*/
.enquiry-item{
    padding: 20px;
    border: 1px solid #ddd;
    border-top: 5px solid #4a548e;
    transition: top 0.2s ease;
    top: 0;
    @include bp(M){
        .enquiry-item{
            margin-bottom: 30px
        }
    }
    &:hover{
        -webkit-box-shadow: 0px 4px 4px 0px rgba(221, 221, 221, 0.5);
        box-shadow: 0px 4px 4px 0px rgba(221, 221, 221, 0.5);
        position: relative;
        top: -1px;
    }
    &:not(:last-child){
        margin-bottom: 30px;
    }
    &.nv-ns{//Not Viewed / Not Shortlisted
        border-top-color: #36a2a1;
        input[type="checkbox"]+label, .enquiry-view-detail{
            color: #36a2a1;
        }
        .enquiry-contact{
            background-color: #36a2a1;
            border-color: #36a2a1;
            &:hover{
                background-color: #268e8d;
                border-color: #268e8d;
            }
        }
        .enquiry-view-detail{
            color: #36a2a1;
        }
    }
    &.v-ns{//Viewed / Not Shortlisted
        border-top-color: #fdedc3;
    }
    &.v-s{//Viewed / Shortlisted
        border-top-color: #ffabb7;
    }
    &.v-ni{//Viewed / Not Intereseted
        border-top-color: #b9b9b9;
    }
    input[type="checkbox"]{
        left: 15px;
        &+label{
            padding-left: 15px;
            font-weight: normal;
            @include bp(M){
                padding-left: 25px;
            }
        }
    }
}
.enquiry-title{
    color: $primary-dark-color;
    margin-bottom: 20px;
    font-family: $base-font-bold-family;
    &.enquiry-contacted:after{
        content: 'Contacted';
        padding: 2px 5px;
        margin-left: 10px;
        background-color: $primary-color;
        border-radius: 20px;
        color: $white;
        font-size: 14px;
        font-family: $base-font-family;
    }
}
.enquirer-detail, 
.enquiry-category,
.enquiry-date,
.enquiry-view-detail{
    padding-bottom: 5px;
    &:before{
        font-family: 'FontAwesome';
        padding-right: 15px;
    }
}
.enquiry-view-detail{
    &:before{
        content: "\f06e";
        padding-right: 5px;
        @include bp(M){
            padding-right: 12px;
        }
    }
}
.enquirer-detail:before{
    content: "\f2c0";
    color: #36a2a1;
}
.enquiry-category:before{
    content: "\f03a";
    color: #888ce8;
}  
.enquiry-date:before{
    content: "\f017";
    color: #f25f85;
}
.enquiry-contact{
    min-width: 100%;
    max-width: 140px;
    text-align: center;
    display: block;
    @include bp(S){
        margin: 20px auto 10px;
        min-width: 140px;
    }
}
.enquiryTel{
    position: relative;
    &:before{
        content: '\f095';
        font-family: 'FontAwesome';
        color: $primary-color;
    }
    &>span{
        padding-left: 8px;
    }
}
/*Dashboard End*/
/*Enquiry Page Start*/
.default-accordian.client-requirements{
    h4.panel-title a:before{
        content: "\f0f6";
    }
    .panel-body{
        padding: 0;
    }
    .enquiry-table{
        .enquiry-question-list{
            padding: 15px;
            &:hover{
                cursor: pointer;
                background-color: rgba(250, 250, 250, 0.5);
            }
            &:not(:last-child){
                border-bottom: 1px solid #ddd;
            }
            &:nth-child(even){
                background-color: #fafafa;
            }
            &:after{
                content: '';
                display: table;
                clear: both;
            }
        }
        .enquiry-question{
            width: 45%;
            float: left;
            padding-right: 15px;
            p{
                color: $primary-dark-color;
            }
        }
        .enquiry-answer{
            width: 55%;
            display: inline-block;
        }
        
        @include bp(XS){
            .enquiry-question{
                width: 100%;
                padding-right: 0;
                padding-bottom: 5px;
                margin-bottom: 5px;
                border-bottom: 1px solid #f2f2f2;
            }
            .enquiry-answer{
                width: 100%;
            }
        }
    }
}
.enquiry-contact-client{
    .enquiry-contact-client-title{
        padding: 15px;
        background-color: #36a2a1;
    }
    .enquiry-contact-client-call{
        border: 1px solid #ddd;
        padding: 30px 15px;
        text-align: center
    }
    .enquiry-contact-client-number{
        padding: 15px 0;
        font-size: 26px;
        color: $primary-dark-color;
        span:last-child{
            color: #36a2a1;
        }
    }
    .btn-enquiry-contact{
        background-color: #36a2a1;
        border-color: #36a2a1;
        &:hover{
            background-color: #268e8d;
            border-color: #268e8d;
        }
    }
    .enquiry-contact-credit > p{
        display: block;
        margin-top: 15px;
        span{
            color: #acacac;
        }
        &:before{
            content: '';
            border: 1px solid #f67b18;
            padding: 0 8px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
}
.enquiry-nav-buttons{
    padding: 15px 0 30px;
    &>a:last-child{
        float: right;
        @include bp(XS){
            float: none;
        }
    }
}

.enquiry-table{
    position: relative;
    &.partialHide{
        max-height: 200px;
        overflow: hidden;
        .showMore{
            bottom: 0;
            background: rgba(255,255,255,0.42);
            background: -moz-linear-gradient(top, rgba(255,255,255,0.42) 0%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.42)), color-stop(49%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
            background: -webkit-linear-gradient(top, rgba(255,255,255,0.42) 0%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 100%);
            background: -o-linear-gradient(top, rgba(255,255,255,0.42) 0%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 100%);
            background: -ms-linear-gradient(top, rgba(255,255,255,0.42) 0%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,0.42) 0%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
        }
    }
    .showMore{
        position: absolute;    
        bottom: -30px;
        left: 0;
        right: 0;
        padding-top: 70px;
        &>a{
            display: block;
            padding: 5px;
            background-color: rgba(227, 227, 227, 0.2);
        }
    }
    &:not(.partialHide){
        margin-bottom: 34px;
        .showMore{ 
            bottom: -34px;
        }
    }
}

.message-container-title{
    position: relative;
    padding-left: 30px;
    &:before{
        content: '\f003';
        font-family: 'FontAwesome';
        position: absolute;
        left: 0;
        font-size: 22px;
        line-height: 18px;
    }
}
.message-thread{
    .posted-by{
        background-color: transparent;
    }
    .message{
        padding: 15px;
        border-right: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        margin: 0;
        &:nth-child(even){
            background-color: #fafafa;
        }
        &>div{
            display: table-cell;
            vertical-align: top;
        }
    }
    .message-image{
        min-width: 80px;
        max-width: 80px;
        padding-right: 10px;
    }
    .message-heading{
        margin-bottom: 10px;
        color: $primary-color;
        span{
            font-size: 14px;
            color: #777;
        }
    }
    .fileAttachment{
        margin-top: 20px;
        display: block;
    }
}

.message-reply{
    padding: 15px;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    textarea{
        padding: 5px 10px;
        margin-top: 20px;
    }
    .message-attachReply{
        color: $primary-color;
        border: 1px solid $primary-color;
        padding: 5px 10px;
        display: inline-block;
        margin-bottom: 20px;
        &:hover {
            background-color: $primary-color;
            color: $white;
        }
    }
    .messaage-attachmentSize{
        font-size: 14px;
        color: #777;
    }
    .btn-1{
        float: right;
        @include bp(S){
            float: none;
        }
    }
}
/*Enquiry Page End*/

/*Buy Credit*/
.credit-buy{
    text-align: center;
    border: 1px solid #36a2a1;
    padding: 40px 15px;;
    margin-bottom: 20px;
    .credit-amount, .credit-price{
        color: #36a2a1;
    }
    .credit-amount{
        border-bottom: 1px solid #ddd;
        padding-bottom: 20px;
    }
    .btn-credit-buy{
        margin: 20px 0 10px;
        min-width: 100%;
        background-color: #36a2a1;
        border-color: #36a2a1;
        &:hover{
            background-color: #268e8d;
            border-color: #268e8d;
        }
    }
    &.best-value{
        position: relative;
        overflow: hidden;
        &:before{
            content: 'Best Value';
            font-size: 14px;
            color: #fff;
            position: absolute;
            left: -30px;
            top: 20px;
            background-color: #36a2a1;
            padding: 5px 30px;
            transform: rotate(-45deg);
        }
    }
    &:hover{
        background-color: #fbfbfb;
        box-shadow: 1px 1px 2px 0px #fbfbfb;
    }
}
/*Buy Credit End*/


/*Profile Page Photo*/
.photo-gallery-img:hover{
    opacity: 0.8;
    cursor: pointer;
}
.photo-gallery-add{
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
    .btn{
        min-width: 120px;
    }
}
/*Profile Page Photo End*/

